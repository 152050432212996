<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">Acessos</h6>
    </div>
    <div class="p-3 card-body">
      <div class="d-flex align-items-center">
        <i class="bi bi-cloudy-fill" style="margin-right: 5px; color: #FFDF06"></i>
        <h6 class="text-sm text-uppercase text-body font-weight-bolder" style="margin-bottom: 0;">
          Star Cloud |
          <a class="text-body" href="http://cloud.minhastar.com.br/" target="_blank">Acessar</a>
        </h6>
      </div>
      <ul class="list-group mt-2">
        <li class="pt-0 text-sm border-0 list-group-item ps-0 pb-1">
          <strong class="text-dark">Usuario:</strong> &nbsp;
          {{ AuthStore.getMe().Nome.split(" ")[0].toLowerCase()
          }}{{ AuthStore.getMe().Id }}
        </li>
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Senha:</strong> &nbsp;
          <span @click="copyToClipboard(senha.slice(0, 8))" class="senha" :class="SenhaCloud ? '' : 'blur-effect'">{{ senha.slice(0, 8)
          }} <i class="far fa-copy" style="font-size: 12px"></i></span>
          <i @click="SenhaCloud = !SenhaCloud" :class="SenhaCloud ? 'fas fa-eye' : 'fas fa-eye-slash'"
            class="ExibirSenha"></i>
        </li>
      </ul>

      <div class="d-flex align-items-center mt-3">
        <i class="bi bi-envelope-at-fill" style="margin-right: 5px; color: #4DAB20"></i>
        <h6 class="text-sm text-uppercase text-body font-weight-bolder" style="margin-bottom: 0;">
          Star Mail |
          <a class="text-body" href="https://webmail.minhastar.com.br/" target="_blank">Acessar</a>
        </h6>
      </div>
      <ul class="list-group mt-2">
        <li class="pt-0 text-sm border-0 list-group-item ps-0 pb-1">
          <strong class="text-dark">Usuario:</strong> &nbsp;
          {{ AuthStore.getMe().Nome.split(" ")[0].toLowerCase() }}{{ AuthStore.getMe().Id }}@minhastar.com.br
        </li>
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Senha:</strong> &nbsp;
          <span @click="copyToClipboard(senha.slice(0, 8))" class="senha" :class="SenhaMail ? '' : 'blur-effect'">{{ senha.slice(0, 8) }} <i
              class="far fa-copy" style="font-size: 12px"></i></span>
          <i @click="SenhaMail = !SenhaMail" :class="SenhaMail ? 'fas fa-eye' : 'fas fa-eye-slash'"
            class="ExibirSenha"></i>
        </li>
      </ul>

      <div class="d-flex align-items-center mt-3">
        <i class="bi bi-file-earmark-pdf-fill" style="margin-right: 5px; color: red;"></i>
        <h6 class="text-sm text-uppercase text-body font-weight-bolder" style="margin-bottom: 0;">
          Politica de privacidade |
          <a class="text-body" href="/arquivos/politica.pdf" target="_blank">Abrir</a>
        </h6>
      </div>
      
      <div class="d-flex align-items-center mt-3">
        <i class="bi bi-file-earmark-pdf-fill" style="margin-right: 5px; color: red;"></i>
        <h6 class="text-sm text-uppercase text-body font-weight-bolder" style="margin-bottom: 0;">
          Contrato de internet 01/2020 |
          <a class="text-body" href="/arquivos/contrato_internet_2020_01.pdf" target="_blank">Abrir</a>
        </h6>
      </div>

      <div class="d-flex align-items-center mt-3">
        <i class="bi bi-file-earmark-pdf-fill" style="margin-right: 5px; color: red;"></i>
        <h6 class="text-sm text-uppercase text-body font-weight-bolder" style="margin-bottom: 0;">
          Contrato de serviços 01/2020 |
          <a class="text-body" href="/arquivos/contrato_sva_2020_01.pdf" target="_blank">Abrir</a>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
let AuthStore;

export default {
  name: "Acessos",
  data() {
    return {
      AuthStore,
      SenhaCloud: false,
      SenhaMail: false,
    }
  },
  methods: {
    copyToClipboard(conteudo) {
      const el = document.createElement("textarea");
      el.value = conteudo;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$notify({ type: "success", text: "Copiado para a área de transferência.", duration: 4000 });
    },
  },
  computed: {
    senha() {
      return AuthStore.getMe().Cpf.replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace(".", "")
        .replace("-", "")
        .replace("/", "")
        .replace(".", "");
    },
  },
  setup() {
    AuthStore = useAuthStore();
  }
}
</script>

<style scoped>
.blur-effect {
  position: relative;
  display: inline-block;
  background: rgba(158, 158, 158, 0.1);
  color: transparent;
  border-radius: 2px;
  user-select: none;
  transition: all 0.5s ease-in-out;
}

.senha{
  padding: 1px 4px;
  transition: all 0.3s ease-in-out;
}

.blur-effect:hover {
  background-color: transparent;
  color: #67748E !important;
  transition: all 0.5s ease;
  cursor: pointer;
}

.ExibirSenha {
  margin-left: 5px;
}

.ExibirSenha:hover {
  cursor: pointer;
}

span:hover{
  cursor: pointer;
}

a{
  text-decoration: underline;
}

a:hover{
  text-decoration: none;
}
</style>
<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">Contatos</h6>
      <p class="text-xs">Olá {{ AuthStore.getMe().Nome.split(" ")[0] }}, fornecemos abaixo todos os contatos da Star para
        atender às suas necessidades.
        Selecione a opção desejada pressionando o botão ao lado de cada número.</p>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          <div class="d-flex">
            <strong class="text-dark">Atendimento:</strong> &nbsp;<div class="text-body text-bold">(51) 2747-2748</div>
            <div class="text-body" style="margin-left: 8px">
              <a class="text-body text-bold" href="https://api.whatsapp.com/send?phone=555127472748" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
              <span class="mx-2">|</span>
              <a class="text-body text-bold" href="tel:+555127472748" target="_blank">
                <i class="fas fa-phone-alt"></i>
              </a>
            </div>
          </div>
          <p class="text-xs mb-1">Pedidos relacionados a transferências de endereço, mudanças de titularidade e
            atendimentos em geral.</p>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <div class="d-flex">
            <strong class="text-dark">Comercial:</strong> &nbsp;<div class="text-body text-bold">(51) 2747-2747</div>
            <div class="text-body" style="margin-left: 8px">
              <a class="text-body text-bold" href="https://api.whatsapp.com/send?phone=555127472747" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
              <span class="mx-2">|</span>
              <a class="text-body text-bold" href="tel:+555127472747" target="_blank">
                <i class="fas fa-phone-alt"></i>
              </a>
            </div>
          </div>
          <p class="text-xs mb-1">Solicitações para novos pontos de internet em sua casa ou empresa.</p>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <div class="d-flex">
            <strong class="text-dark">Financeiro:</strong> &nbsp;
            <div class="text-body text-bold">(51) 99587-9656</div>
            <div class="text-body" style="margin-left: 8px">
              <a class="text-body text-bold" href="https://api.whatsapp.com/send?phone=5551995879656" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
              <span class="mx-2">|</span>
              <a class="text-body text-bold" href="tel:+555127472747" target="_blank">
                <i class="fas fa-phone-alt"></i>
              </a>
            </div>
          </div>
          <p class="text-xs mb-1">Auxílios sobre pagamentos e/ou contratos.</p>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <div class="d-flex">
            <strong class="text-dark">Suporte:</strong> &nbsp;<div class="text-body text-bold">(51) 2747-2747</div>
            <div class="text-body" style="margin-left: 8px">
              <a class="text-body text-bold" href="https://api.whatsapp.com/send?phone=55512747-2747" target="_blank">
                <i class="fab fa-whatsapp"></i>
              </a>
              <span class="mx-2">|</span>
              <a class="text-body text-bold" href="tel:+555127472747" target="_blank">
                <i class="fas fa-phone-alt"></i>
              </a>
            </div>
          </div>
          <p class="text-xs mb-1">Assistências relacionadas a sua conexão com a internet.</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
let AuthStore;

export default {
  name: "ProfileInfoCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      location: String,
      default: () => { },
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
  data() {
    return {
      AuthStore,
    }
  },
  setup() {
    AuthStore = useAuthStore();
  }
};
</script>

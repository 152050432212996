<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
    </div>
    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="@/assets/img/user.jpg"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ AuthStore.getMe().Nome }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="mt-3 row">
      <div class="col-12 col-md-6 col-xl-4">
        <Acessos />
      </div>
      <div class="mt-4 col-12 col-md-6 col-xl-4 mt-md-0">
        <Contatos />
      </div>
      <div class="mt-4 col-12 col-xl-4 mt-xl-0">
        <Aplicativos />
      </div>
    </div>
    
  </div>
</template>

<script>
import Aplicativos from "../components/Aplicativos.vue";
import Acessos from "../components/Acessos.vue";
import Contatos from "../components/Contatos.vue";
import sophie from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import peterson from "@/assets/img/team-4.jpg";
import nick from "@/assets/img/team-3.jpg";
import img1 from "@/assets/img/home-decor-1.jpg";
import img2 from "@/assets/img/home-decor-2.jpg";
import img3 from "@/assets/img/home-decor-3.jpg";
import team1 from "@/assets/img/team-1.jpg";
import team2 from "@/assets/img/team-2.jpg";
import team3 from "@/assets/img/team-3.jpg";
import team4 from "@/assets/img/team-4.jpg";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { useAuthStore } from "@/stores/auth.js";
let AuthStore;

export default {
  name: "ProfileOverview",
  components: {
    Acessos,
    Aplicativos,
    Contatos,
  },
  data() {
    return {
      showMenu: false,
      sophie,
      marie,
      ivana,
      peterson,
      nick,
      img1,
      team1,
      team2,
      team3,
      team4,
      img2,
      img3,
      faFacebook,
      faTwitter,
      faInstagram,
      AuthStore,
    };
  },

  mounted() {
    //this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  /*beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },*/

  setup() {
    AuthStore = useAuthStore();
  }
};
</script>
